const Decimals = (number) => {
    return Math.floor(Number(number) * 10000) / 10000;
};


const changeNum = (n) => {
    let num = String(n)
    let arr = num.split('.')
    let result;

    if (arr[1]) {
        result = Number(arr[0]).toLocaleString() + "." + arr[1]
    }
    else {
        result = Number(arr[0]).toLocaleString()
    }

    return result;
}

export { Decimals, changeNum }