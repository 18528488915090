import '../css/login.css'
import '../css/referrer.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faFileCircleXmark, faCaretDown, faCaretUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import axios from 'axios';
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { changeNum, Decimals } from './common/function'
import { useTranslation } from "react-i18next";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';

function Dashboard() {
    let Typemode = useSelector((state) => { return state.Typemode.value; });

    const { t } = useTranslation();
    let dispatch = useDispatch()
    let navigator = useNavigate()

    const [createbtn, setCreateBtn] = useState(false)
    const [refbtn, setRefBtn] = useState(false)
    const [noticebtn, setNoticeBtn] = useState(false)
    const [myInfo, setMyInfo] = useState([])
    const [subR, setSubR] = useState([])
    const [subT, setSubT] = useState([])
    const [wallet, setWallet] = useState([])
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState("")
    const [settlementDate, setSettlementDate] = useState([])
    const [settlementTodayDate, setSettlementTodaytDate] = useState([])
    const [settlementYesterdayDate, setSettlementYesterdaytDate] = useState([])
    const [settlementLiveDate, setSettlementLivetDate] = useState([])
    const [sum, setSum] = useState([0, 0])
    const [reload, setReload] = useState(false)
    const [KOLdata, setKOLData] = useState({})

    useEffect(() => {
        DashboardData()
        WalletData()
        SettlementData()
        SettlementTodayData()
        SettlementLiveData()
        CheckKOLPercent()
    }, [reload])

    const CheckKOLPercent = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/kol-self', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                if (tmp.d.binanceSelfP === 0 || tmp.d.okxSelfP === 0 || tmp.d.bybitSelfP === 0) {
                    setNoticeBtn(true)
                }
                setKOLData(tmp?.d ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        if (settlementDate?.length > 0) {
            let bn = 0
            let okx = 0
            let by = 0
            for (let i = 0; i < settlementDate.length; i++) {
                let tmp = settlementDate[i]
                bn += Number(tmp?.binance_income ?? 0)
                okx += Number(tmp?.okx_income ?? 0)
                by += Number(tmp?.bybit_income ?? 0)
            }
            setSum([bn, okx, by])
        }
    }, [settlementDate])

    const DashboardData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/dashboard/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setMyInfo(tmp.d.info && tmp.d.info[0] ? tmp.d.info[0] : [])
                setSubR(tmp.d?.sub ?? [])
                setSubT(tmp.d?.subT ?? [])
                setNoticeBtn(tmp.d.info && tmp.d.info[0] ? tmp.d.info[0].change_status == "Y" ? true : false : false)
            }
            if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const WalletData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/wallet', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setWallet(tmp.d && tmp.d[0] ? tmp.d[0] : [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/settlement', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setSettlementDate(tmp?.d ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementTodayData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/settlement/today', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setSettlementTodaytDate(tmp?.d?.today[0] ?? [])
                setSettlementYesterdaytDate(tmp?.d?.yesterday[0] ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const SettlementLiveData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/settlement/realtime', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp.c >= 0) {
                setSettlementLivetDate(tmp?.d)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <div className="App-box">
            <Header loca={t('footer_0')} type={0} />
            {
                createbtn
                    ?
                    <CreateModal setCreateBtn={setCreateBtn} onChangeModal={onChangeModal} setMessage={setMessage} setReload={setReload} KOLdata={KOLdata} />
                    :
                    ""
            }
            {
                refbtn
                    ?
                    <RefModal setRefBtn={setRefBtn} onChangeModal={onChangeModal} setMessage={setMessage} setReload={setReload} KOLdata={KOLdata} />
                    :
                    ""
            }
            {
                noticebtn
                    ?
                    <NoticeModal setNoticeBtn={setNoticeBtn} KOLdata={KOLdata} />
                    :
                    ""
            }
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>
                            {
                                message?.code === 0
                                    ?
                                    <i className="fa-regular fa-circle-check" style={{ color: "#20B26C" }}></i>
                                    :
                                    <i className="fa-regular fa-circle-xmark" style={{ color: "#EF454A" }}></i>
                            }
                        </div>
                        <div>{message?.msg}</div>
                    </div>
                    :
                    ""
            }
            <div className='dashboard-1'>
                <div className='dashboard-1-3'>
                    <div className='dashboard-1-3-1'>{t('dashboard_0')}</div>
                    <CopyToClipboard text={myInfo?.broker ?? " "}>
                        <div className='dashboard-1-3-2' onClick={() => { onChangeModal(); setMessage({ code: 0, msg: t('dashboard_1') }) }}>
                            {`${myInfo?.broker ?? " "}`}
                            <i className="fa-regular fa-clone" style={{ marginLeft: "5px", transform: "scaleX(-1)", color: "#757575" }}></i>
                        </div>
                    </CopyToClipboard>
                </div>
                <div className='dashboard-1-4'>
                    <div className='code-btn btn' onClick={() => setRefBtn(true)}>
                        {t('dashboard_24')}
                    </div>
                    {
                        myInfo?.broker_grade == "1"
                            ?
                            <div className='code-btn btn' onClick={() => setCreateBtn(true)}>
                                {t('dashboard_23')}
                            </div>
                            :
                            ""
                    }
                </div>
            </div>

            <div className='dashboard-2'>
                <div className='dashboard-2-1'>{t('dashboard_2')}</div>
                <div className='dashboard-6'>
                    <div className='dashboard-6-1'>
                        <img src='/img/binance_logo.png' style={{ width: "25px" }} />
                        <div className='dashboard-6-1-1'>
                            <div className='dashboard-6-1-2'>Binance</div>
                            <div className='dashboard-6-1-3'>{changeNum(Number(wallet?.binance_free ?? 0) + Number(wallet?.binance_lock ?? 0))} <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-6-2'>
                        <div className='dashboard-6-2-1 btn' onClick={() => navigator(`/withdraw/Binance`)}>{t('dashboard_3')}</div>
                    </div>
                </div>
                <div className='dashboard-6'>
                    <div className='dashboard-6-1'>
                        <img src='/img/okx_logo.png' style={{ width: "25px" }} />
                        <div className='dashboard-6-1-1'>
                            <div className='dashboard-6-1-2'>OKX</div>
                            <div className='dashboard-6-1-3'>{changeNum(Number(wallet?.okx_free ?? 0) + Number(wallet?.okx_lock ?? 0))} <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-6-2'>
                        <div className='dashboard-6-2-1 btn' onClick={() => navigator(`/withdraw/OKX`)}>{t('dashboard_3')}</div>
                    </div>
                </div>
                <div className='dashboard-6'>
                    <div className='dashboard-6-1'>
                        <img src='/img/bybit_logo.png' style={{ width: "25px" }} />
                        <div className='dashboard-6-1-1'>
                            <div className='dashboard-6-1-2'>Bybit</div>
                            <div className='dashboard-6-1-3'>{changeNum(Number(wallet?.bybit_free ?? 0) + Number(wallet?.bybit_lock ?? 0))} <span>USDT</span></div>
                        </div>
                    </div>
                    <div className='dashboard-6-2'>
                        <div className='dashboard-6-2-1 btn' onClick={() => navigator(`/withdraw/Bybit`)}>{t('dashboard_3')}</div>
                    </div>
                </div>
            </div>
            <div className='dashboard-2'>
                <div className='dashboard-2-6'>
                    <div className='dashboard-2-1'>{t('dashboard_4')}</div>
                    <div className='dashboard-2-2'>{Decimals(Number(settlementTodayDate?.binance_income ?? 0) + Number(settlementTodayDate?.okx_income ?? 0) + Number(settlementTodayDate?.bybit_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                    <div className={`dashboard-2-2-1 ${Number(settlementTodayDate?.binance_income ?? 0) + Number(settlementTodayDate?.okx_income ?? 0) + Number(settlementTodayDate?.bybit_income ?? 0)
                        - Number(settlementYesterdayDate?.binance_income ?? 0) - Number(settlementYesterdayDate?.okx_income ?? 0) - Number(settlementYesterdayDate?.bybit_income ?? 0) >= 0 ? "green" : "red"}`}>
                        <FontAwesomeIcon icon={Number(settlementTodayDate?.binance_income ?? 0) + Number(settlementTodayDate?.okx_income ?? 0) + Number(settlementTodayDate?.bybit_income ?? 0)
                            - Number(settlementYesterdayDate?.binance_income ?? 0) - Number(settlementYesterdayDate?.okx_income ?? 0) - Number(settlementYesterdayDate?.bybit_income ?? 0) >= 0 ? faCaretUp : faCaretDown} />
                        {Decimals(
                            Number(settlementTodayDate?.binance_income ?? 0) + Number(settlementTodayDate?.okx_income ?? 0) + Number(settlementTodayDate?.bybit_income ?? 0)
                            - Number(settlementYesterdayDate?.binance_income ?? 0) - Number(settlementYesterdayDate?.okx_income ?? 0) - Number(settlementYesterdayDate?.bybit_income ?? 0)
                        ).toFixed(4)}
                    </div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/settlement`)}>{t('dashboard_5')}</div>
                    </div>
                </div>
                <div className='dashboard-2-3'>
                    <div className='dashboard-2-3-1'><img src='/img/binance_logo.png' style={{ width: "25px" }} />Binance</div>
                    <div className='dashboard-2-3-2'>
                        <div className='dashboard-2-3-2-1'>{Decimals(Number(settlementTodayDate?.binance_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                        <div className={`dashboard-2-3-2-2 ${Number(settlementTodayDate?.binance_income ?? 0) - Number(settlementYesterdayDate?.binance_income ?? 0) >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={Number(settlementTodayDate?.binance_income ?? 0) - Number(settlementYesterdayDate?.binance_income ?? 0) >= 0 ? faCaretUp : faCaretDown} />
                            {Decimals(Number(settlementTodayDate?.binance_income ?? 0) - Number(settlementYesterdayDate?.binance_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
                <div className='dashboard-2-3'>
                    <div className='dashboard-2-3-1'><img src='/img/okx_logo.png' style={{ width: "25px" }} />OKX</div>
                    <div className='dashboard-2-3-2'>
                        <div className='dashboard-2-3-2-1'>{Decimals(Number(settlementTodayDate?.okx_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                        <div className={`dashboard-2-3-2-2 ${Number(settlementTodayDate?.okx_income ?? 0) - Number(settlementYesterdayDate?.okx_income ?? 0) >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={Number(settlementTodayDate?.okx_income ?? 0) - Number(settlementYesterdayDate?.okx_income ?? 0) >= 0 ? faCaretUp : faCaretDown} />
                            {Decimals(Number(settlementTodayDate?.okx_income ?? 0) - Number(settlementYesterdayDate?.okx_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
                <div className='dashboard-2-3'>
                    <div className='dashboard-2-3-1'><img src='/img/bybit_logo.png' style={{ width: "25px" }} />Bybit</div>
                    <div className='dashboard-2-3-2'>
                        <div className='dashboard-2-3-2-1'>{Decimals(Number(settlementTodayDate?.bybit_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                        <div className={`dashboard-2-3-2-2 ${Number(settlementTodayDate?.bybit_income ?? 0) - Number(settlementYesterdayDate?.bybit_income ?? 0) >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={Number(settlementTodayDate?.bybit_income ?? 0) - Number(settlementYesterdayDate?.bybit_income ?? 0) >= 0 ? faCaretUp : faCaretDown} />
                            {Decimals(Number(settlementTodayDate?.bybit_income ?? 0) - Number(settlementYesterdayDate?.bybit_income ?? 0)).toFixed(4)} <span>USDT</span></div>
                    </div>
                </div>
                <div className='hr-dash'></div>
                <div className='dashboard-2-6'>
                    <div className='dashboard-2-1'>{t('dashboard_6')}</div>
                    <div className='dashboard-2-2' style={{ paddingBottom: "0px", fontSize: "18px" }}>{Decimals(sum[0] ?? 0 + sum[1] ?? 0 + sum[2] ?? 0).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/menu/settlement`)}>{t('dashboard_5')}</div>
                    </div>
                </div>
                <div className='dashboard-2-6' style={{ marginTop: "10px" }}>
                    <div className='dashboard-2-1'>{t('dashboard_7')}</div>
                    <div className='dashboard-2-2' style={{ paddingBottom: "0px", fontSize: "18px" }}>{Decimals(settlementLiveDate?.est_income ?? 0).toFixed(4)} <span>USDT</span></div>
                    <div className='dashboard-2-6-1'>
                        <div className='code-btn btn' onClick={() => navigator(`/menu/history`)}>{t('dashboard_5')}</div>
                    </div>
                </div>
            </div>
            {
                Typemode
                    ?
                    <div className='dashboard-2'>
                        <div className='dashboard-2-1-1'>{t('dashboard_8')}</div>
                        <div className='dashboard-2-4' style={{ paddingRight: "10px" }}>
                            <div className='dashboard-2-4-3 op6'>{t('dashboard_9')}</div>
                            <div className='dashboard-2-4-4 op6'>{t('dashboard_10')}</div>
                            <div className='dashboard-2-4-4 op6'>{t('dashboard_11')}</div>
                        </div>
                        {
                            subR?.length > 0
                                ?
                                subR.map(function (a, i) {
                                    return (
                                        <ReferrerList key={i} item={a} />
                                    )
                                })
                                :
                                <div className='noData'>
                                    <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                </div>

                        }
                    </div>
                    :
                    <div className='dashboard-2'>
                        <div className='dashboard-2-1-1'>{t('dashboard_12')}</div>
                        <div className='dashboard-2-4'>
                            <div className='dashboard-2-4-1 op6'>{t('dashboard_13')}</div>
                            <div className='dashboard-2-4-2 op6'>{t('dashboard_14')}</div>
                            <div className='dashboard-2-4-2-1 op6'>{t('dashboard_15')}</div>
                        </div>
                        {
                            subT?.length > 0
                                ?
                                subT.map(function (a, i) {
                                    return (
                                        <TraderList key={i} item={a} no={i + 1} />
                                    )
                                })
                                :
                                <div className='noData'>
                                    <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                </div>

                        }
                    </div>
            }

            <Footer num={1} />
        </div >
    )
}

function ReferrerList({ item }) {
    return (
        <Link to={`/subReferrer/info/${item.id}`} className='dashboard-2-4' style={{ paddingRight: "10px" }}>
            <div className='sub-arrow-right'>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className='dashboard-2-4-3'>{item.id}</div>
            <div className='dashboard-2-4-4'>{item.sub_referrer_count}</div>
            <div className='dashboard-2-4-4'>{item.sub_trader_count}</div>
        </Link>
    )
}

function TraderList({ item, no }) {
    return (
        <div className='dashboard-2-4'>
            <div className='dashboard-2-4-1'>{no}</div>
            <div className='dashboard-2-4-2'>{item.id}</div>
            <div className='dashboard-2-4-2-1'>{item.volume}</div>
        </div>
    )
}


function CreateModal({ setCreateBtn, onChangeModal, setMessage, setReload, KOLdata }) {
    const { t } = useTranslation();
    const [uid, setUID] = useState("")
    const [otp, setOTP] = useState("")
    const [code, setCode] = useState("")
    const [rate, setRate] = useState("")
    const [state, setState] = useState(false)
    const [inputs, setInputs] = useState(Array(3).fill(Array(3).fill("0")));

    const handleInputChange = (rowIndex, colIndex, event, total) => {
        const { value } = event.target
        const onlyNumber = value.replace(/[^0-9]/g, "")

        const newInputs = inputs.map((row, rIndex) =>
            row.map((value, cIndex) =>
                (rIndex === rowIndex && cIndex === colIndex) ? Number(total) >= Number(onlyNumber) ? onlyNumber : total : value
            )
        );
        setInputs(newInputs);
    };

    const onChangeUID = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setUID(onlyNumber)
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const onChangeCode = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "")
        setCode(onlyNumber)
    }

    useEffect(() => {
        // CheckKOLPercent()
        if (KOLdata) {
            let arr = [
                [String(KOLdata.binanceMasterP + KOLdata.binanceSelfP), String(KOLdata.binanceMasterP), String(KOLdata.binanceSelfP)],
                [String(KOLdata.okxMasterP + KOLdata.okxSelfP), String(KOLdata.okxMasterP), String(KOLdata.okxSelfP)],
                [String(KOLdata.bybitMasterP + KOLdata.bybitSelfP), String(KOLdata.bybitMasterP), String(KOLdata.bybitSelfP)]
            ]
            setInputs(arr)
        }
    }, [KOLdata])

    const CheckKOLPercent = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/kol-self', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                let arr = [
                    [String(tmp.d.binanceMasterP + tmp.d.binanceSelfP), String(tmp.d.binanceMasterP), String(tmp.d.binanceSelfP)],
                    [String(tmp.d.okxMasterP + tmp.d.okxSelfP), String(tmp.d.okxMasterP), String(tmp.d.okxSelfP)],
                    [String(tmp.d.bybitMasterP + tmp.d.bybitSelfP), String(tmp.d.bybitMasterP), String(tmp.d.bybitSelfP)]
                ]
                setInputs(arr)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSendOTP = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/regist/referrer', {
            withCredentials: true,
            params: {
                uid: uid
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_1') })
                setState(true)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        await axios.post(process.env.REACT_APP_API + '/api/v1/broker/user/kol-regist', {
            uid: uid,
            code: code,
            binance: inputs[0][2] ?? 0,
            okx: inputs[1][2] ?? 0,
            bybit: inputs[2][2] ?? 0,
            certifyNumber: otp,
        }, {
            withCredentials: true
        }).then((response) => {
            let tmp = response?.data
            // console.log(tmp)
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_2') })
                setTimeout(() => {
                    setReload(e => !e)
                    setCreateBtn(false)
                }, 500)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div className='modal' onClick={() => setCreateBtn(false)}></div>
            <div className='modal-box-1'>
                <div className='modal-1'>
                    <FontAwesomeIcon icon={faXmark} className='modal-x' onClick={() => setCreateBtn(false)} />
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_16')}</div>
                        <div className='modal-2-1-1'>
                            <input value={uid} onChange={onChangeUID} maxLength={6} className='modal-2-1-input' />
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_19')}</div>
                        <div className='modal-2-1-1'>
                            <input value={code} onChange={onChangeCode} maxLength={8} className='modal-2-1-input' />
                        </div>
                    </div>
                    <div className='modal-2-2'>
                        <div className='modal-1-1-1'>{t('dashboard_20')}(%)</div>
                        <div className='modal-2-2-1'>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1'></div>
                                <div className='modal-2-2-2-1'>Total(%)</div>
                                <div className='modal-2-2-2-1'>ME(%)</div>
                                <div className='modal-2-2-2-1'>KOL(%)</div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Binance</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][0]) - Number(inputs[0][2])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[0][2]} onChange={(event) => handleInputChange(0, 2, event, inputs[0][0])} />
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>OKX</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][0]) - Number(inputs[1][2])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[1][2]} onChange={(event) => handleInputChange(1, 2, event, inputs[1][0])} />
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Bybit</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][0]) - Number(inputs[2][2])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[2][2]} onChange={(event) => handleInputChange(2, 2, event, inputs[2][0])} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_17')}</div>
                        <div className='modal-2-1-1'>
                            <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                            <div className='modal-2-1-btn' onClick={() => onSendOTP()}>{t('dashboard_18')}</div>
                        </div>
                    </div>
                    <div className={`modal-btn ${state ? "" : "disable"}`} onClick={() => onSubmit()}>{t('dashboard_21')}</div>
                </div>
            </div>
        </>
    )
}

function RefModal({ setRefBtn, onChangeModal, setMessage, setReload, KOLdata }) {
    const { t } = useTranslation();
    const [uid, setUID] = useState("")
    const [otp, setOTP] = useState("")
    const [state, setState] = useState(false)
    const [inputs, setInputs] = useState(Array(3).fill(Array(3).fill("0")));

    const handleInputChange = (rowIndex, colIndex, event, total) => {
        const { value } = event.target
        const onlyNumber = value.replace(/[^0-9]/g, "")

        const newInputs = inputs.map((row, rIndex) =>
            row.map((value, cIndex) =>
                (rIndex === rowIndex && cIndex === colIndex) ? Number(total) >= Number(onlyNumber) ? onlyNumber : total : value
            )
        );
        setInputs(newInputs);
    };

    const onChangeUID = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setUID(onlyNumber)
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    useEffect(() => {
        if (KOLdata) {
            let arr = [
                [String(KOLdata.binanceMasterP + KOLdata.binanceSelfP), String(KOLdata.binanceMasterP), String(KOLdata.binanceSelfP)],
                [String(KOLdata.okxMasterP + KOLdata.okxSelfP), String(KOLdata.okxMasterP), String(KOLdata.okxSelfP)],
                [String(KOLdata.bybitMasterP + KOLdata.bybitSelfP), String(KOLdata.bybitMasterP), String(KOLdata.bybitSelfP)]
            ]
            setInputs(arr)
        }
    }, [KOLdata])

    const onSendOTP = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/regist/referrer', {
            withCredentials: true,
            params: {
                uid: uid
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_1') })
                setState(true)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        await axios.post(process.env.REACT_APP_API + '/api/v1/broker/user/kol-self', {
            uid: uid,
            binance: inputs[0][2] ?? 0,
            okx: inputs[1][2] ?? 0,
            bybit: inputs[2][2] ?? 0,
            certifyNumber: otp,
        }, {
            withCredentials: true
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_2') })
                setTimeout(() => {
                    setReload(e => !e)
                    setRefBtn(false)
                }, 500)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div className='modal' onClick={() => setRefBtn(false)}></div>
            <div className='modal-box-1'>
                <div className='modal-1'>
                    <FontAwesomeIcon icon={faXmark} className='modal-x' onClick={() => setRefBtn(false)} />
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_25')}</div>
                        <div className='modal-2-1-1'>
                            <input value={uid} onChange={onChangeUID} maxLength={6} className='modal-2-1-input' />
                        </div>
                    </div>
                    <div className='modal-2-2'>
                        <div className='modal-1-1-1'>{t('dashboard_20')}(%)</div>
                        <div className='modal-2-2-1'>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1'></div>
                                <div className='modal-2-2-2-1'>Total(%)</div>
                                <div className='modal-2-2-2-1'>ME(%)</div>
                                <div className='modal-2-2-2-1'>KOL(%)</div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Binance</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][0]) - Number(inputs[0][2])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[0][2]} onChange={(event) => handleInputChange(0, 2, event, inputs[0][0])} />
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>OKX</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][0]) - Number(inputs[1][2])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[1][2]} onChange={(event) => handleInputChange(1, 2, event, inputs[1][0])} />
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Bybit</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][0]) - Number(inputs[2][2])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    <input type='number' value={inputs[2][2]} onChange={(event) => handleInputChange(2, 2, event, inputs[2][0])} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('dashboard_17')}</div>
                        <div className='modal-2-1-1'>
                            <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                            <div className='modal-2-1-btn' onClick={() => onSendOTP()}>{t('dashboard_18')}</div>
                        </div>
                    </div>
                    <div className={`modal-btn ${state ? "" : "disable"}`} onClick={() => onSubmit()}>{t('dashboard_26')}</div>
                </div>
            </div>
        </>
    )
}

function NoticeModal({ setNoticeBtn, KOLdata }) {
    const { t } = useTranslation();
    const [inputs, setInputs] = useState(Array(3).fill(Array(3).fill("0")));

    useEffect(() => {
        if (KOLdata) {
            let arr = [
                [String(KOLdata.binanceMasterP + KOLdata.binanceSelfP), String(KOLdata.binanceMasterP), String(KOLdata.binanceSelfP)],
                [String(KOLdata.okxMasterP + KOLdata.okxSelfP), String(KOLdata.okxMasterP), String(KOLdata.okxSelfP)],
                [String(KOLdata.bybitMasterP + KOLdata.bybitSelfP), String(KOLdata.bybitMasterP), String(KOLdata.bybitSelfP)]
            ]
            setInputs(arr)
        }
    }, [KOLdata])

    return (
        <>
            <div className='modal' onClick={() => setNoticeBtn(false)}></div>
            <div className='modal-box-2'>
                <div className='modal-1' style={{ paddingTop: "30px" }}>
                    <div className='modal-2-1' style={{ justifyContent: "center" }}>
                        {t('dashboard_28')}
                    </div>
                    <div className='modal-2-2'>
                        <div className='modal-1-1-1'>{t('dashboard_20')}(%)</div>
                        <div className='modal-2-2-1'>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1'></div>
                                <div className='modal-2-2-2-1'>Total(%)</div>
                                <div className='modal-2-2-2-1'>ME(%)</div>
                                <div className='modal-2-2-2-1'>KOL(%)</div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Binance</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[0][2])}
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>OKX</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[1][2])}
                                </div>
                            </div>
                            <div className='modal-2-2-2'>
                                <div className='modal-2-2-2-1' style={{ justifyContent: "flex-start" }}>Bybit</div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][0])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][1])}
                                </div>
                                <div className='modal-2-2-2-1'>
                                    {Number(inputs[2][2])}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modal-btn`} onClick={() => setNoticeBtn(false)}>{t('dashboard_27')}</div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;