import { configureStore, createSlice } from '@reduxjs/toolkit'

let login = createSlice({
    name: 'login',
    initialState: { value: false },
    reducers: {
        checkLogin(state, action) {
            state.value = action.payload
        }
    }
})

let MyInfo = createSlice({
    name: 'MyInfo',
    initialState: [],
    reducers: {
        checkMyInfo(state, action) {
            return action.payload
        }
    }
})

let Darkmode = createSlice({
    name: 'Darkmode',
    initialState: { value: false },
    reducers: {
        changeDark(state, action) {
            localStorage.setItem('darkMode', action.payload ? "light" : "dark")
            state.value = action.payload
        }
    }
})

let Typemode = createSlice({
    name: 'type',
    initialState: { value: true },
    reducers: {
        changeType(state, action) {
            localStorage.setItem('typeMode', action.payload ? "referrer" : "trader")
            state.value = action.payload
        }
    }
})


let Languages = createSlice({
    name: 'Languages',
    initialState: { value: "en-US" },
    reducers: {
        changeLanguages(state, action) {
            localStorage.setItem("language", action.payload)
            state.value = action.payload
        }
    }
})


export let { checkLogin } = login.actions
export let { checkMyInfo } = MyInfo.actions
export let { changeDark } = Darkmode.actions
export let { changeType } = Typemode.actions
export let { changeLanguages } = Languages.actions

export default configureStore({
    reducer: {
        login: login.reducer,
        MyInfo: MyInfo.reducer,
        Darkmode: Darkmode.reducer,
        Typemode: Typemode.reducer,
        Languages: Languages.reducer,
    }
}) 