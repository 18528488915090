import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faExchange } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard/src";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'
import { checkLogin } from '../../store';
import { useDispatch } from 'react-redux';

function Withdraw_Amount() {
    const { t } = useTranslation();
    let navigator = useNavigate();
    const location = useLocation();
    let dispatch = useDispatch();

    const [amount, setAmount] = useState("")
    const [ex, setEx] = useState(location.state === null ? "" : location.state.ex)
    const [network, setNetwork] = useState(location.state === null ? "" : location.state.network)
    const [address, setAddress] = useState(location.state === null ? "" : location.state.address)
    const [tag, setTag] = useState(location.state === null ? "" : location.state.tag)
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState("")
    const [wallet, setWallet] = useState([])

    useEffect(() => {
        if (location.state === null) {
            navigator(`/withdraw`)
        }
        WalletData()
    }, [location])

    const onChangeAmount = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setAmount(onlyNumber);
    }

    const onClickWithdraw = () => {
        if (ex == "Binance") {
            Binance_Withdraw()
        }
        else if (ex == "OKX") {

        }
        else if (ex == "Bybit") {
            Bybit_Withdraw()
        }
    }

    const Binance_Withdraw = async () => {
        await axios.post(process.env.REACT_APP_API + '/api/v1/broker/user/withdraw/binance', {
            network: network.network,
            address: address,
            addressTag: tag,
            amount: amount
        }, {
            withCredentials: true
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                navigator(`/dashboard`)
            }
            else if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const Bybit_Withdraw = async () => {
        await axios.post(process.env.REACT_APP_API + '/api/v1/broker/user/withdraw/bybit', {
            chain: network.network,
            address: address,
            tag: tag,
            amount: amount
        }, {
            withCredentials: true
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code >= 0) {
                navigator(`/dashboard`)
            }
            else if (tmp?.code == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.msg })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const WalletData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/wallet', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setWallet(tmp.d && tmp.d[0] ? tmp.d[0] : [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <div className="App-box-1">
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>
                            {
                                message?.code === 0
                                    ?
                                    <i className="fa-regular fa-circle-check" style={{ color: "#20B26C" }}></i>
                                    :
                                    <i className="fa-regular fa-circle-xmark" style={{ color: "#EF454A" }}></i>
                            }
                        </div>
                        <div>{message?.msg}</div>
                    </div>
                    :
                    ""
            }
            <Link to={`/withdraw`} className='arrow-left'>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className='withdraw'>
                <div className='withdraw-1-top'>
                    <div className='withdraw-1-top-1'>{t('withdraw_10')}</div>
                    <div className='withdraw-1-top-2'>
                        <img src={ex == "Binance" ? '/img/binance_logo.png' : ex == "OKX" ? '/img/okx_logo.png' : '/img/bybit_logo.png'} style={{ width: "25px" }} />
                        <div>{ex}</div>
                    </div>
                    <div className='withdraw-1-top-3'>
                        <div>{t('withdraw_3')}</div>
                        <div>{ex == "Binance" ? wallet?.binance_free ?? 0 : ex == "OKX" ? wallet?.okx_free ?? 0 : wallet?.bybit_free ?? 0} USDT</div>
                    </div>
                </div>
                <div className='login-1-1'>{t('withdraw_12')}</div>
                <div className='withdraw-2' style={{ marginTop: "20px" }}>
                    <div className='withdraw-1-5'>
                        <input className='withdraw-1-2-input' value={amount} onChange={onChangeAmount} />
                    </div>
                    <div className='withdraw-1-6'>USDT</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_13')}</div>
                    <div className='withdraw-1-4'>{ex}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_14')}</div>
                    <div className='withdraw-1-4'>{network.network}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_15')}</div>
                    <div className='withdraw-1-4'>
                        <CopyToClipboard text={address ?? ""}>
                            <div className='withdraw-1-4-1' onClick={() => { onChangeModal(); setMessage({ code: 0, msg: t('dashboard_1') }) }}>
                                {shortenString(address)}
                                <i className="fa-regular fa-clone" style={{ marginLeft: "5px", transform: "scaleX(-1)", color: "#757575" }}></i>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_16')}</div>
                    <div className='withdraw-1-4'>
                        <CopyToClipboard text={tag ?? ""}>
                            <div className='withdraw-1-4-1' onClick={() => { onChangeModal(); setMessage({ code: 0, msg: t('dashboard_1') }) }}>
                                {shortenString(tag)}
                                <i className="fa-regular fa-clone" style={{ marginLeft: "5px", transform: "scaleX(-1)", color: "#757575" }}></i>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_17')}</div>
                    <div className='withdraw-1-4'>{network?.minAmount ?? 0}</div>
                </div>
                <div className='withdraw-3'>
                    <div className='withdraw-1-3'>{t('withdraw_18')}</div>
                    <div className='withdraw-1-4'>{network?.minFee ?? 0}</div>
                </div>
                <div className='withdraw-1-btn' onClick={() => onClickWithdraw()}>{t('withdraw_19')}</div>
            </div >

            <Footer num={4} />
        </div >
    )
}

function shortenString(str) {
    if (str.length <= 10) {
        return str;
    }

    const start = str.slice(0, 6);
    const end = str.slice(-4);

    return `${start}...${end}`;
}

export default Withdraw_Amount;