import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faAngleRight, faFileCircleXmark, faChartSimple, faUsers, faChevronDown, faChevronUp, faXmark, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useTranslation } from "react-i18next";

import Header from '../../Nav/header';
import Footer from '../../Nav/footer';

import '../css/login.css'
import '../css/referrer.css'
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';

function SubReferrer() {
    const { t } = useTranslation();
    let Typemode = useSelector((state) => { return state.Typemode.value; });
    let navigator = useNavigate()
    let dispatch = useDispatch();

    const [btn, setBtn] = useState(false)
    const [btn1, setBtn1] = useState(false)
    const [myInfo, setMyInfo] = useState([])
    const [subR, setSubR] = useState([])
    const [subT, setSubT] = useState([])

    const [refBtn, setRefBtn] = useState(true)
    const [traderBtn, setTraderBtn] = useState(true)

    const [subInfo, setSubInfo] = useState([])
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState({ code: 0, msg: "" })

    useEffect(() => {
        DashboardData()
    }, [])

    const DashboardData = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/get/dashboard/info', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                setMyInfo(tmp.d.info && tmp.d.info[0] ? tmp.d.info[0] : [])
                setSubR(tmp.d?.sub ?? [])
                setSubT(tmp.d?.subT ?? [])
            }
            if (tmp?.c == -9999) {
                dispatch(checkLogin(false))
                localStorage.removeItem("login")
                navigator(`/`)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const [traderInput, setTraderInput] = useState("")
    const [refInput, setRefInput] = useState("")

    const onChangeTraderInput = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        setTraderInput(onlyNumber);
    }

    const onChangeRefInput = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        setRefInput(onlyNumber);
    }

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }


    let filter_subR = [];
    let filter_subT = [];

    filter_subR = subR.filter((p) => {
        return (String(p.id).toLocaleLowerCase().includes(refInput.toLocaleLowerCase()))
    })

    filter_subT = subT.filter((p) => {
        return (String(p.id).toLocaleLowerCase().includes(traderInput.toLocaleLowerCase()))
    })

    return (
        <div className="App-box">
            <Header loca={t('footer_1')} type={0} />
            {
                btn
                    ?
                    <Modal setBtn={setBtn} subInfo={subInfo} onChangeModal={onChangeModal} setMessage={setMessage} />
                    :
                    ""
            }
            {
                btn1
                    ?
                    <Modal1 setBtn1={setBtn1} subInfo={subInfo} onChangeModal={onChangeModal} setMessage={setMessage} />
                    :
                    ""
            }
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>
                            {
                                message?.code === 0
                                    ?
                                    <i className="fa-regular fa-circle-check" style={{ color: "#20B26C" }}></i>
                                    :
                                    <i className="fa-regular fa-circle-xmark" style={{ color: "#EF454A" }}></i>
                            }
                        </div>
                        <div>{message?.msg}</div>
                    </div>
                    :
                    ""
            }
            <div className='sub-1'>
                <div className='sub-1-1'>
                    <div className='sub-1-1-1'><FontAwesomeIcon icon={faUsers} style={{ color: "gray", paddingRight: "5px" }} /> {t('subRef_1')}</div>
                    <div>{filter_subR?.length ?? 0}</div>
                </div>
                <div className='sub-1-1'>
                    <div className='sub-1-1-1'><FontAwesomeIcon icon={faChartSimple} style={{ color: "gray", paddingRight: "5px" }} /> {t('subRef_2')}</div>
                    <div>{filter_subT?.length ?? 0}</div>
                </div>
            </div>
            {
                Typemode
                    ?
                    <>
                        <div className='sub-2'>
                            <div className='sub-2-2'>
                                <div>{t('subRef_1')}</div>
                                <div className='sub-2-2-1'>
                                    <input className='sub-2-2-1-input' value={refInput} onChange={onChangeRefInput} placeholder={t('subRef_3')} />
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                            </div>
                            <div onClick={() => setRefBtn(e => !e)}>{filter_subR?.length ?? 0} <FontAwesomeIcon icon={refBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            refBtn
                                ?
                                filter_subR?.length > 0
                                    ?
                                    filter_subR.map(function (a, i) {
                                        return (
                                            <ReferrerList key={i} item={a} setBtn={setBtn} setBtn1={setBtn1} setSubInfo={setSubInfo} />
                                        )
                                    })
                                    :
                                    <div className='dashboard-2'>
                                        <div className='noData'>
                                            <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                        </div>
                                    </div>
                                :
                                ""
                        }
                        <div className='sub-2'>
                            <div className='sub-2-2'>
                                <div>{t('subRef_2')}</div>
                                <div className='sub-2-2-1'>
                                    <input className='sub-2-2-1-input' value={traderInput} onChange={onChangeTraderInput} placeholder={t('subRef_3')} />
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                            </div>
                            <div onClick={() => setTraderBtn(e => !e)}>{filter_subT?.length ?? 0} <FontAwesomeIcon icon={traderBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            traderBtn
                                ?
                                <div className='dashboard-2'>
                                    <div className='dashboard-2-4'>
                                        <div className='dashboard-2-4-1'>{t('subRef_4')}</div>
                                        <div className='dashboard-2-4-2'>{t('subRef_5')}</div>
                                        <div className='dashboard-2-4-2-1'>{t('subRef_6')}</div>
                                    </div>
                                    {
                                        filter_subT?.length > 0
                                            ?
                                            filter_subT.map(function (a, i) {
                                                return (
                                                    <ListItem key={i} item={a} />
                                                )
                                            })
                                            :
                                            <div className='noData'>
                                                <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                            </div>
                                    }
                                </div>
                                :
                                ""
                        }
                    </>
                    :
                    <>
                        <div className='sub-2'>
                            <div className='sub-2-2'>
                                <div>{t('subRef_2')}</div>
                                <div className='sub-2-2-1'>
                                    <input className='sub-2-2-1-input' value={traderInput} onChange={onChangeTraderInput} placeholder={t('subRef_3')} />
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                            </div>
                            <div onClick={() => setTraderBtn(e => !e)}>{filter_subT?.length ?? 0} <FontAwesomeIcon icon={traderBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            traderBtn
                                ?
                                <div className='dashboard-2'>
                                    <div className='dashboard-2-4'>
                                        <div className='dashboard-2-4-1'>{t('subRef_4')}</div>
                                        <div className='dashboard-2-4-2'>{t('subRef_5')}</div>
                                        <div className='dashboard-2-4-2-1'>{t('subRef_6')}</div>
                                    </div>
                                    {
                                        filter_subT?.length > 0
                                            ?
                                            filter_subT.map(function (a, i) {
                                                return (
                                                    <ListItem key={i} item={a} />
                                                )
                                            })
                                            :
                                            <div className='noData'>
                                                <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                            </div>
                                    }
                                </div>
                                :
                                ""
                        }
                        <div className='sub-2'>
                            <div className='sub-2-2'>
                                <div>{t('subRef_1')}</div>
                                <div className='sub-2-2-1'>
                                    <input className='sub-2-2-1-input' value={refInput} onChange={onChangeRefInput} placeholder={t('subRef_3')} />
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>
                            </div>
                            <div onClick={() => setRefBtn(e => !e)}>{filter_subR?.length ?? 0} <FontAwesomeIcon icon={refBtn ? faCaretDown : faCaretUp} /></div>
                        </div>
                        {
                            refBtn
                                ?
                                filter_subR?.length > 0
                                    ?
                                    filter_subR.map(function (a, i) {
                                        return (
                                            <ReferrerList key={i} item={a} setBtn={setBtn} setBtn1={setBtn1} setSubInfo={setSubInfo} />
                                        )
                                    })
                                    :
                                    <div className='dashboard-2'>
                                        <div className='noData'>
                                            <FontAwesomeIcon icon={faFileCircleXmark} className='noData-svg' />
                                        </div>
                                    </div>
                                :
                                ""
                        }
                    </>
            }

            <Footer num={2} />
        </div>
    )
}

function ReferrerList({ item, setBtn, setBtn1, setSubInfo }) {
    const { t } = useTranslation();
    const [drop, setDrop] = useState(false)
    const NoneClick = (event) => {
        event.preventDefault();
    };

    return (
        <Link to={`/subReferrer/info/${item.id}`} className='dashboard-2'>
            <div className='sub-arrow-right-1'>
                <FontAwesomeIcon icon={faAngleRight} />
            </div>
            <div className='sub-3'>
                <div className='sub-3-1 border-right'>
                    <div className='sub-3-1-1'>{t('subRef_7')}</div>
                    <div>{item.id}</div>
                </div>
                <div className='sub-3-1 border-right'>
                    <div className='sub-3-1-1'>{t('subRef_8')}</div>
                    <div>{item.sub_referrer_count}</div>
                </div>
                <div className='sub-3-1'>
                    <div className='sub-3-1-1'>{t('subRef_9')}</div>
                    <div>{item.sub_trader_count}</div>
                </div>
            </div>
            <div onClick={NoneClick} style={{ width: "100%" }}>
                <div className='sub-4-2' onClick={() => setDrop(e => !e)} style={{ marginBottom: drop ? "15px" : "0" }}>
                    <FontAwesomeIcon icon={drop ? faChevronUp : faChevronDown} />
                </div>
                {
                    drop
                        ?
                        <div className='sub-4' onClick={NoneClick}>
                            <div className='sub-4-1 btn' style={{ width: "100%" }} onClick={() => { setBtn(true); setSubInfo(item) }}>{t('subRef_10')}</div>
                            {/* <div className='sub-4-1 btn' onClick={() => { setBtn1(true); setSubInfo(item) }}>{t('subRef_11')}</div> */}
                        </div>
                        :
                        ""
                }
            </div>
        </Link >
    )
}

function ListItem({ item }) {
    return (
        <div className='dashboard-2-4'>
            <div className='dashboard-2-4-1'>{item.id}</div>
            <div className='dashboard-2-4-2'>{Number(item.volume).toFixed(3)}</div>
            <div className='dashboard-2-4-2-1'>{item.regist_date}</div>
        </div>
    )
}

function Modal({ setBtn, subInfo, onChangeModal, setMessage }) {
    const { t } = useTranslation();
    const [select, setSelect] = useState(0)
    const [otp, setOTP] = useState("")
    const [cost, setCost] = useState("")
    const [state, setState] = useState(false)

    const onChangeCost = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setCost(onlyNumber)
    }

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const onSendOTP = async () => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/transfer', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_1') })
                setState(true)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmitTransfer = async () => {
        await axios.post(process.env.REACT_APP_API + '/api/v1/broker/user/transfer', {
            subId: subInfo?.broker_code,
            ex: select === 0 ? "binance" : select === 1 ? "okx" : "bybit",
            amt: cost,
            certifyNumber: otp
        }, {
            withCredentials: true
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.c >= 0) {
                onChangeModal()
                setMessage({ code: 0, msg: t('message_4') })
                setTimeout(() => {
                    setBtn(false)
                }, 500)
            }
            else {
                onChangeModal()
                setMessage({ code: 1, msg: tmp.m })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <>
            <div className='modal' onClick={() => setBtn(false)}></div>
            <div className='modal-box'>
                <div className='modal-1'>
                    <FontAwesomeIcon icon={faXmark} className='modal-x' onClick={() => setBtn(false)} />
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>{t('subRef_12')}</div>
                        <div className='modal-1-1-2'>{subInfo?.broker_code}</div>
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>{t('subRef_13')}</div>
                        <div className='modal-1-1-2'>
                            <div className={`modal-1-1-2-1 ${select === 0 ? "on" : ""}`} onClick={() => setSelect(0)}>Binance</div>
                            /
                            <div className={`modal-1-1-2-1 ${select === 1 ? "on" : ""}`} onClick={() => setSelect(1)}>OKX</div>
                            /
                            <div className={`modal-1-1-2-1 ${select === 2 ? "on" : ""}`} onClick={() => setSelect(2)}>Bybit</div>
                        </div>
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>{t('subRef_14')}</div>
                        <div className='modal-1-1-2'>
                            <input value={cost} onChange={onChangeCost} className='modal-1-1-2-input' /> <span>USDT</span>
                        </div>
                    </div>
                    <div className='modal-2-1'>
                        <div className='modal-1-1-1'>{t('subRef_15')}</div>
                        <div className='modal-2-1-1'>
                            <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                            <div className='modal-2-1-btn' onClick={() => onSendOTP()}>{t('subRef_16')}</div>
                        </div>
                    </div>
                    <div className={`modal-btn ${state ? "" : "disable"}`} onClick={() => onSubmitTransfer()}>{t('subRef_17')}</div>
                </div>
            </div>
        </>
    )
}

function Modal1({ setBtn1, subInfo, onChangeModal, setMessage }) {
    const { t } = useTranslation();
    const [cost, setCost] = useState("")
    const [state, setState] = useState(0)
    const [otp, setOTP] = useState("")

    const onChangeOTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setOTP(onlyNumber)
    }

    const onChangeCost = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        if (Number(onlyNumber) > Number(subInfo?.rebate_rate) * 100) setCost(Number(subInfo?.rebate_rate) * 100)
        else setCost(onlyNumber)
    }

    const onSubmitUpdate = async () => {
        if (Number(cost) > 0) {
            await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/update/rate', {
                withCredentials: true,
                params: {
                    subId: subInfo?.broker_code,
                    rate: (Number(cost) / 100).toFixed(2)
                }
            }).then((response) => {
                let tmp = response?.data
                if (tmp?.c >= 0) {
                    onChangeModal()
                    setMessage({ code: 0, msg: t('message_1') })
                    setState(1)
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            alert(t('message_5'))
        }
    }

    const onSubmitUpdateRate = async () => {
        if (Number(otp) > 0) {
            await axios.get(process.env.REACT_APP_API + '/api/v1/broker/user/update/rate', {
                withCredentials: true,
                params: {
                    subId: subInfo?.broker_code,
                    rate: (Number(cost) / 100).toFixed(2),
                    certifyNum: otp
                }
            }).then((response) => {
                let tmp = response?.data
                if (tmp?.c >= 0) {
                    onChangeModal()
                    setMessage({ code: 0, msg: t('message_6') })
                    setTimeout(() => {
                        setBtn1(false)
                    }, 500)
                }
                else {
                    onChangeModal()
                    setMessage({ code: 1, msg: tmp.m })
                }
            }).catch(error => {
                console.error(error);
            });
        }
        else {
            alert(t('message_7'))
        }
    }

    const onClick = () => {
        if (state === 0) {
            onSubmitUpdate()
        }
        else {
            onSubmitUpdateRate()
        }
    }

    return (
        <>
            <div className='modal' onClick={() => setBtn1(false)}></div>
            <div className='modal-box'>
                <div className='modal-1'>
                    <FontAwesomeIcon icon={faXmark} className='modal-x' onClick={() => setBtn1(false)} />
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>{t('subRef_18')}</div>
                        <div className='modal-1-1-2'>{subInfo?.broker_code}</div>
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>{t('subRef_19')}</div>
                        <div className='modal-1-1-2'>{Number(subInfo?.rebate_rate) * 100}%</div>
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>{t('subRef_20')}</div>
                        <div className='modal-1-1-2'>
                            <input value={cost} onChange={onChangeCost} maxLength={2} className='modal-1-1-2-input' disabled={state === 0 ? false : true} /> <span>%</span>
                        </div>
                    </div>
                    {
                        state === 1
                            ?
                            <div className='modal-2-1'>
                                <div className='modal-1-1-1'>{t('subRef_21')}</div>
                                <div className='modal-2-1-1'>
                                    <input value={otp} onChange={onChangeOTP} maxLength={6} className='modal-2-1-input' />
                                </div>
                            </div>
                            :
                            ""
                    }
                    <div className='modal-btn' onClick={() => onClick()}>{state === 0 ? t('message_1') : t('message_6')}</div>
                </div>
            </div>
        </>
    )
}

export default SubReferrer;