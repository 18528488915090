import './nav.css'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguages } from '../store';
import { useTranslation } from "react-i18next";
import i18n from "../lang/i18n";
import '@fortawesome/fontawesome-free/css/all.css';

function Header({ loca, type }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let dark = useSelector((state) => { return state.Darkmode.value; });
    let Languages = useSelector((state) => { return state.Languages.value; });
    let darkname = dark ? "dark" : ""
    const [lan, setLan] = useState(false);
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState("")

    const onChangeLang = (e) => {
        // if (e == "ja-JP") {
        //     document.body.classList.add('font_JP');
        // }
        // else {
        //     document.body.classList.remove('font_JP');
        // }
        i18n.changeLanguage(e);
        dispatch(changeLanguages(e));
        if (Languages != e) {
            onChangeModal()
            setMessage({ code: 0, msg: t('message_3') })
        }
    };

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <div className={`header ${type === 0 ? "" : "white"}`}>
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>
                            {
                                message?.code === 0
                                    ?
                                    <i className="fa-regular fa-circle-check" style={{ color: "#20B26C" }}></i>
                                    :
                                    <i className="fa-regular fa-circle-xmark" style={{ color: "#EF454A" }}></i>
                            }
                        </div>
                        <div>{message?.msg}</div>
                    </div>
                    :
                    ""
            }
            <Link to={type === 2 ? `` : `/dashboard`} className='header-left'>
                <img src='/img/RF_small.png' />
            </Link>
            <div className='header-center'>
                <div>{loca}</div>
            </div>
            <div className='header-right'>
                {/* {
                    type === 2
                        ?
                        ""
                        :
                        <FontAwesomeIcon icon={faBell} />
                } */}
                <div className='header-right-1'>
                    <div className='header-right-1' onClick={() => setLan(e => !e)}>
                        <FontAwesomeIcon icon={faGlobe} style={{ fontSize: '19px', color: dark ? "#ebe9e9" : "#595959" }} />
                    </div>
                    <div className={`${lan ? "header-modal" : ""}`} onClick={() => setLan(false)}>
                    </div>
                    <ul className={`header-right-icon-1-dropdown ${lan ? "lanEnter" : "lanLeave"} ${darkname}`}>
                        <li>
                            <Link onClick={() => { onChangeLang("ko-KR"); setLan(false) }} >
                                <div className="header-right-icon-1-dropdown-1">
                                    <div className="header-right-icon-1-dropdown-ico">
                                        <img src="/img/south-korea.png"></img>
                                    </div>
                                    <div className="header-right-icon-1-dropdown-wrap">
                                        <div className="header-right-icon-1-dropdown-tit">한국어</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => { onChangeLang("en-US"); setLan(false) }} >
                                <div className="header-right-icon-1-dropdown-1">
                                    <div className="header-right-icon-1-dropdown-ico">
                                        <img src="/img/united-states.png"></img>
                                    </div>
                                    <div className="header-right-icon-1-dropdown-wrap">
                                        <div className="header-right-icon-1-dropdown-tit">English</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link onClick={() => { onChangeLang("ja-JP"); setLan(false) }} >
                                <div className="header-right-icon-1-dropdown-1">
                                    <div className="header-right-icon-1-dropdown-ico">
                                        <img src="/img/japan.png"></img>
                                    </div>
                                    <div className="header-right-icon-1-dropdown-wrap">
                                        <div className="header-right-icon-1-dropdown-tit">日本語</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default Header;